<template>
  <header class="header-container">
    <slot></slot>
  </header>
</template>

<script>
export default {
  name: "LayoutHeader"
}
</script>

<style scoped>

</style>