<template>
  <el-container class="main-container" direction="vertical">
    <slot></slot>
  </el-container>
</template>

<script>
export default {
  name: "LayoutMain"
}
</script>

<style scoped>

</style>