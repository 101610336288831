<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render(h, context) {
    const {icon, title} = context.props
    const vnodes = []

    if (icon) {
      vnodes.push(<i class={[icon, 'sub-el-icon']}/>)
    }

    if (title) {
      vnodes.push(<span slot='title'>{(title)}</span>)
    }
    return vnodes
  }
}
</script>

<style scoped>
    .sub-el-icon {
        font-size: 20px;
        width: 20px;
        height: 20px;
    }
</style>
