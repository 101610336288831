import el from "element-ui/lib/locale/lang/zh-CN" // 加载element的内容
import fu from "fit2cloud-ui/src/locale/lang/zh-CN" // 加载fit2cloud的内容


const message = {
    commons: {
        message_box: {
            alert: "警告",
            confirm: "确认",
            prompt: "提示",
        },
        personal: {
            profile: "个人信息",
            exit: "退出",
            project_url: "项目地址",
            issue: "问题反馈",
            talk: "参与讨论",
            star: "点亮 Star",
            version: "版本",
            introduction: "KubePi 是一个现代化的 K8s 面板。",
            introduction2: "KubePi 允许管理员导入多个 Kubernetes 集群，并且通过权限控制，将不同 cluster、namespace 的权限分配给指定用户。它允许开发人员管理 Kubernetes 集群中运行的应用程序并对其进行故障排查，供开发人员更好地处理 Kubernetes 集群中的复杂性。",
        },
        help: {
            help: "帮助",
            about: "关于",
            business_support: "商业支持",
            ko_docs: "官方文档",
        },
        button: {
            detail: "详情",
            delete: "删除",
            skip: "跳过",
            import: "导入",
            create: "创建",
            cancel: "取消",
            login: "登录",
            confirm: "确定",
            add: "添加",
            edit: "编辑",
            all_select: "全选",
            upload: "上传文件",
            search: "搜索",
            rbac_manage: "授权",
            sync: "同步",
            bind: "绑定",
            change_password: '改密',
        },
        table: {
            name: "名称",
            kind: "类型",
            created_time: "创建时间",
            age: "存活时间",
            status: "状态",
            action: "操作",
            creat_by: "创建者",
            built_in: "内置",
            description: "描述",
            empty_text: "没有内容显示",
            mfa_enable: "MFA认证"
        },
        header: {
            help_doc: "帮助文档",
            support: "获取支持",
            guide: "引导",
            guide_text: "感谢选择本产品, 是否立即开始注册您的第一个集群?",
        },
        bool: {
            true: "是",
            false: "否"
        },
        enable: {
            true: "启用",
            false: "禁用"
        },
        search: {
            quickSearch: "搜索"
        },
        form: {
            select_placeholder: "请选择"
        },
        validate: {
            limit: "长度在 {0} 到 {1} 个字符",
            input: "请输入{0}",
            select: "请选择{0}",
            required: "必填项",
            email: "请输入有效的电子邮箱",
            number_limit: "请输入正确的数字",
            password_help: "有效密码:8-30位,英文字母+数字+特殊字符(~!@_#$%^&*,可选)",
            name_not_compliant: "该名称不符合命名规范",
            name_rules: "仅支持小写英文、数字和-",
        },
        msg: {
            create_success: "创建成功",
            delete_success: "删除成功",
            update_success: "修改成功",
            duplicate_failed: "数据重复，操作失败！",
            no_data: "暂无数据",
        },
        confirm_message: {
            delete: "此操作不可撤销, 是否继续?",
            create_success: "创建成功",
            save_success: "保存成功",
        },
        login: {
            username_or_email: "用户名或邮箱",
            password: "密码",
            title: "登录 KubePi",
            welcome: "欢迎回来，请输入用户名和密码登录",
            expires: "认证信息已过期，请重新登录",
            mfa_helper: "使用 MFA 验证器应用扫描以下二维码，获取6位验证码",
            mfa_login_helper: "请输入MFA验证器的6位验证码",
        },
    },
    business: {
        cluster: {
            cluster: "集群",
            namespace: "命名空间",
            scope: "作用域",
            version: "版本",
            list: "集群列表",
            import: "导入集群",
            edit_cluster: "编辑集群信息",
            edit: "编辑",
            nodes: "节点",
            label: "标签",
            description: "描述",
            cluster_detail: "集群详情",
            connect_setting: "连接设置",
            connect_direction: "连接方向",
            connect_forward: "正向连接",
            connect_backward: "反向连接",
            authenticate_setting: "认证设置",
            certificate: "证书",
            authenticate_mode: "认证模式",
            expect: "敬请期待",
            management: "管理",
            open_dashboard: "控制台",
            cluster_version: "版本",
            member: "成员",
            role: "角色",
            administrator: "管理员",
            viewer: "只读者",
            custom: "自定义",
            contains_namespace: "(包含命名空间角色)",
            rule: "规则",
            config_file: "kubeconfig 文件",
            config_content: "config 内容",
            hidden_cluster: "隐藏不可访问的集群",
            namespace_role_form_check_msg: "命名空间或者角色列表不能为空",
            api_group: "API 组",
            resource: "资源",
            verb: "动作",
            cluster_role_form_check_msg: "API组,资源,动作不为空",
            user_not_in_cluster: "您不是此集群的成员，无法访问此集群的控制台，请联系管理员添加您为集群成员",
            ready: "正常",
            not_ready: "异常",
            repo: "仓库",
            repo_auth: "仓库授权",
        },
        cluster_role: {
            none: "无",
            cluster_administrator: "集群管理员",
            cluster_viewer: "只读用户",
        },
        user: {
            user_management: "用户管理",
            username: "用户名",
            nickname: "昵称",
            email: "邮箱",
            user_list: "用户列表",
            role_list: "角色列表",
            user: "用户",
            role: "角色",
            template: "模版",
            base_on_exists_role: "基于已有角色",
            permission: "权限",
            permission_setting: "权限设置",
            password: "密码",
            confirm_password: "确认密码",
            old_password: "原密码",
            new_password: "新密码",
            change_password: "修改密码",
            resource_name: "资源名称",
            please_input_password: "请输入密码",
            please_input_password_agin: "请再次输入密码",
            password_not_equal: "两次输入密码不一致",
            ldap: "LDAP",
            ldap_address: "地址",
            ldap_tls: "tls",
            ldap_port: "端口",
            ldap_username: "用户DN",
            ldap_password: "密码",
            ldap_filter_dn: "用户过滤 OU",
            ldap_filter_rule: "用户过滤规则",
            ldap_helper: "注意：无法获取到 Name 映射属性的用户不会匹配",
            ldap_sync: "开始同步，稍后请查看用户列表",
            ldap_sync_error: "请先保存",
            type: "类型",
            ldap_mapping: "用户属性映射",
            ldap_mapping_helper: "用户属性映射代表怎样将LDAP中用户属性映射到kubepi用户上，name, nickName,email 是kubepi的用户需要属性",
            ldap_test: "测试连接",
            test_result: "连接成功,匹配到{count}个用户",
            test_login: "测试登录",
            login_success: "测试登录成功",
            login_failed: "登录失败",
            import_user: "导入用户",
            import_enable: "是否可导入",
            import_user_success: "导入成功",
            import_user_failed: "导入失败用户{user}",
            ldap_remake: "重置",
            time_limit: "连接超时时间",
            size_limit: "搜索分页数量",
        },
        system: {
            system_log: "日志审计",
            operation_log: "操作日志",
            operator: "操作人",
            operation: "操作",
            operation_domain: "资源类型",
            specific_information: "操作对象",
            login_log: "登录日志",
            username: "用户名",
            ip: "登录IP",
            city: "登录城市",
        },
        image_repos: {
          list: "镜像仓库",
          name: "名称",
          endpoint: "地址",
          downloadUrl: "镜像下载地址",
          username: "用户名",
          password: "密码",
          type: "类型",
          repo: "镜像库",
          load_repo: "加载镜像库",
          auth: "认证",
          allow_anonymous: "允许匿名docker pull",
          repo_null: "镜像库为空",
          images: "镜像列表",
          push_image: "推送镜像到当前项目",
          version: "版本",
        }
    },
}

const apiObjects = {
    users: "用户",
    roles: "角色",
    clusters: "集群",
    systems: "日志审计",
}

const apiVerbs = {
    update: "编辑",
    delete: "删除",
    get: "详情",
    list: "列表",
    create: "创建",
    privilege: "特权",
    authorization: "授权"
}

const system_logs = {
    post: "创建",
    put: "修改",
    delete: "删除",
    clusters: "集群列表",
    users: "用户",
    roles: "角色",
    systems: "日志审计",
    clusters_members: "集群成员",
    clusters_clusterroles: "集群角色",
    clusters_repos: "集群仓库",
    imagerepos: "镜像仓库",
    ldap: "LDAP",
    sync: "同步",
    import: "导入",
    testConnect: "测试",
    testLogin: "测试",
}


const description = {
    i18n_user_administrator: "超级管理员，拥有所有对象的权限",
    i18n_user_manage_cluster: "集群管理员，拥有集群对象的所有权限",
    i18n_user_manage_rbac: "角色与用户管理员，拥有用户管理对象的所有权限",
    i18n_user_manage_repo: "镜像仓库管理员，拥有镜像仓库对象的所有权限",
    i18n_user_manage_readonly: "只读用户，只拥有所有对象的访问权限",
    i18n_user_common_user: "普通用户，只拥有集群对象访问权限",
    i18n_user_manage_chart: "Chart仓库管理员， 拥有对Chart仓库的所有权限",

    i18n_cluster_owner: "集群拥有者,拥有所有对象权限",
    i18n_cluster_viewer: "集群只读用户,拥有所有对象的只读权限",
    i18n_manage_cluster_rbac: "集群访问控制管理员, 拥有 ClusterRole、ClusterRoleBinding 对象的所有权限",
    i18n_view_cluster_rbac: "集群访问控制只读用户, 拥有 ClusterRole、ClusterRoleBinding 对象的只读权限",
    i18n_manage_cluster_storage: "集群存储管理员,拥有 StorageClass、PersistentVolume 对象的所有权限",
    i18n_view_cluster_storage: "集群存储只读用户,拥有 StorageClass、PersistentVolume 对象的只读权限",
    i18n_manage_namespaces: "命名空间管理员,拥有对 Namespace 对象的所有权限",
    i18n_view_namespaces: "命名空间只读用户,拥有对 Namespace 对象的所有权限",
    i18n_view_events: "集群事件只读用户, 拥有 Events 对象的只读权限",
    i18n_view_nodes: "节点只读用户,拥有 Node 对象的只读权限",

    i18n_manage_nodes: "节点管理员,拥有 Node 对象的只读权限",
    i18n_manage_crd: "自定义资源管理员,拥有 CustomResourceDefinition 对象的所有权限",
    i18n_view_crd: "自定义资源管理员,拥有 CustomResourceDefinition 对象的只读权限",


    i18n_manage_config: "配置管理员, 拥有当前命名空间 ConfigMap、Secret、ResourceQuotas、LimitRanges、HorizontalPodAutoscalers 和 PodDisruptionBudget 对象的所有权限",
    i18n_view_config: "配置只读用户, 拥有当前命名空间 ConfigMap、Secret、ResourceQuotas、LimitRanges、HorizontalPodAutoscalers 和 PodDisruptionBudget 对象的只读权限",
    i18n_namespace_owner: "命名空间拥有者，拥有当前命名空间内的所有对象的所有权限",
    i18n_namespace_viewer: "命名空间只读用户，拥有当前命名空间内的所有对象的只读权限",
    i18n_view_workload: "工作负载只读用户，拥有当前命名空间内 DaemonSet、StatefulSet、Deployment、Job、CronJob和Pod的只读权限",
    i18n_manage_workload: "工作负载管理员,工作负载只读用户，拥有当前命名空间内 DaemonSet、StatefulSet、Deployment、Job、CronJob和Pod的所有权限",
    i18n_manage_storage: "存储管理员，拥有当前命名空间内 PersistentVolumeClaim 对象的所有权限",
    i18n_view_storage: "存储只读用户，拥有当前命名空间内 PersistentVolumeClaim 对象的只读权限",
    i18n_view_service_discovery: "服务发现只读用户,拥有当前命名空间内 Service、Endpoint、Ingress和NetworkPolicy 对象的只读权限",
    i18n_manage_service_discovery: "服务发现管理员,拥有当前命名空间内 Service、Endpoint、Ingress和NetworkPolicy 对象的所有权限",
    i18n_manage_rbac: "命名空间访问控制,拥有当前命名空间内 Role、RoleBinding 和 ServiceAccount 对象的所有权限",
    i18n_view_rbac: "命名空间访问控制 只读用户,拥有当前命名空间内 Role、RoleBinding 和 ServiceAccount 对象的只读权限",

    i18n_manage_appmarket: "应用市场管理员, 拥有对应用市场的所有权限"

}

export default {
    ...el,
    ...fu,
    ...message,
    ...apiObjects,
    ...apiVerbs,
    ...description,
    ...system_logs

}
